import config from '../../service/configs/config.js';
import { SteppedEase, TimelineMax, TweenMax } from 'gsap';
import { cupService } from '../../service/cup/cupService.js';

const componentName = 'cupWithDynamicAchieve';

class Cup {
  static $inject = ['$scope', '$document', '$q', 'config', 'cupService', '$window', '$timeout'];

  constructor($scope, $document, $q, config, cupService, $window, $timeout) {
    $scope.preloader = true;
    $scope.collection = [];

    const cupGlassImage = `${config.cdn}/${config.siteName}/cup-page/cup_glass_cut.png`;
    const cupGoldImage = `${config.cdn}/${config.siteName}/cup-page/cup_gold_cut.png`;

    const cupGoldImagePromise = cupService.getImage(cupGoldImage);
    const cupGlassImagePromise = cupService.getImage(cupGlassImage);
    const achievementsPromise = cupService.getAchievement({ category: 'cup', count: this.count, order: '' });

    const cupWrap = $document[0].querySelector('.cup__wrap');
    const cupBack = cupWrap.querySelector('.cup__back');
    const cupFront = cupWrap.querySelector('.cup__front-img');
    const cupProgress = cupWrap.querySelector('.cup__front');
    const progress = cupWrap.querySelector('.cup-progress');
    const progressLine = cupWrap.querySelector('.cup-progress__line');

    const progressDuration = 1;
    const cupConfig = {
      height: '100%',
      ease: SteppedEase.config(100),
    };

    const twistDuration = 1;
    const twistSegment = 1000;
    const twistConfig = {
      reversed: true,
      backgroundPosition: '-10800px',
      ease: SteppedEase.config(30),
    };

    const progressTimeLine = new TimelineMax({ paused: true });
    const mainProgress = TweenMax.to(progress, progressDuration, cupConfig);
    const progressCupProgress = TweenMax.to(cupProgress, progressDuration, cupConfig);
    const progressLineProgress = TweenMax.to(progressLine, progressDuration, cupConfig);
    progressTimeLine.add(mainProgress, 0);
    progressTimeLine.add(progressCupProgress, 0);
    progressTimeLine.add(progressLineProgress, 0);

    const twistTimeLine = new TimelineMax({ paused: true });
    const twistBackTween = TweenMax.to(cupBack, twistDuration, twistConfig);
    const twistFrontTween = TweenMax.to(cupFront, twistDuration, twistConfig);

    twistTimeLine.add(twistBackTween, 0);
    twistTimeLine.add(twistFrontTween, 0);

    const twistCup = (element) => {
      const deltaX = (element.view.innerWidth - twistSegment) * 0.5;
      const mousePosition = (element.pageX - deltaX) / twistSegment;

      if (0 < mousePosition && mousePosition <= 1) {
        twistTimeLine.progress(mousePosition);
      }
    };

    $q.all([achievementsPromise, cupGoldImagePromise, cupGlassImagePromise])
      .then((answer) => {
        $scope.collection = answer[0];
        $scope.mainGoal = answer[0].reduce((acc, item) => acc + item.prize, 0);
        $scope.mainAchievement = $scope.collection.slice(-1)[0];

        cupWrap.classList.add('linda-img-loaded');
        progressTimeLine.addLabel('progressCurrent', cupService.progressCup);
        progressTimeLine.addPause('progressCurrent');
        progressTimeLine.play(0);
        $timeout(() => {
          cupService.buildMarks($document[0].querySelectorAll('.cup-mark'));
        }, 0);
      })
      .catch((error) => console.warn(error))
      .finally(() => {
        $scope.preloader = false;
      });

    this.$onInit = () => $window.addEventListener('mousemove', twistCup);
    this.$onDestroy = () => $window.removeEventListener('mousemove', twistCup);
  }
}

app.component(componentName, {
  controller: Cup,
  binding: {
    count: '<',
  },
  template: app.getTU(componentName),
});
