import '../../../service/domain/modal.js';
import '../../../service/helper.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/trustly/paynplay.js';
import '../../../service/domain/system.js';
import '../../../service/configs/config.js';
import '../../../service/configs/registration.js';
import '../../../service/analytics/p161.js';
(function () {
  'use strict';

  const component = { name: 'lindaTrustlyRegistrationModal' };

  controller.$inject = [
    '$scope',
    'modal',
    'helper',
    'contentBuffer',
    'paynplay',
    'system',
    'config',
    'registration',
    'p161',
  ];
  function controller($scope, _modal, _helper, _contentBuffer, _paynplay, _system, _config, _registration, _p161) {
    function controller($scope, _modal, _helper, _contentBuffer, _paynplay, _system, _config, _registration, _p161) {
      const params = _contentBuffer.read('modal');
      const modalName = _modal.current;

      $scope.hide_bonuses = params.is_registration_bonuses_shown;
      $scope.person = params.profile;
      $scope.preloader = false;

      $scope.promotions = [];
      $scope.activePromotion = {};
      $scope.activePromotionIndex = null;

      $scope.formField = {
        name: { disabled: Boolean($scope.person.name) },
        surname: { disabled: Boolean($scope.person.surname) },
        email: { disabled: Boolean($scope.person.email) },
        phone: { disabled: Boolean($scope.person.phone) },
        city: { disabled: Boolean($scope.person.city) },
        postcode: { disabled: Boolean($scope.person.postcode) },
        address: { disabled: Boolean($scope.person.address) },
        gender: { disabled: Boolean($scope.person.gender) },
        birthday: { disabled: Boolean($scope.person.birthday) },
      };

      $scope.formData = {
        name: $scope.person.name,
        surname: $scope.person.surname,
        email: $scope.person.email,
        phone: $scope.person.phone,
        city: $scope.person.city,
        postcode: $scope.person.postcode,
        address: $scope.person.address,
        gender: $scope.person.gender || 'M',
        gdpr_data: false,
        accept: false,
        gdpr_marketing: false,
        birthday: $scope.person.birthday,
        enable_trigger_codes: true,
      };

      _registration.addPromotionsToScope($scope);
      _registration.addAvatarsToScope($scope);

      if (_config.jurisdiction === 'germany') {
        $scope.birth_countries = [];
        $scope.nationalities = [];

        $scope.formField.birth_country = { disabled: Boolean($scope.person.birth_country) };
        $scope.formField.birth_name = { disabled: Boolean($scope.person.birth_name) };
        $scope.formField.birth_place = { disabled: Boolean($scope.person.birth_place) };
        $scope.formField.nationality = { disabled: Boolean($scope.person.nationality) };

        $scope.formData.birth_country = $scope.person.birth_country;
        $scope.formData.birth_name = $scope.person.birth_name;
        $scope.formData.birth_place = $scope.person.birth_place;
        $scope.formData.nationality = $scope.person.nationality;

        $scope.preloader = true;

        _system
          .nationality()
          .then((answer) => {
            const nationalities = [];
            const birth_countries = [];

            answer.result.forEach((obj) => {
              nationalities.push({
                name: obj.nationality,
                code: obj.alpha_2_code,
              });
              birth_countries.push({
                name: obj.en_short_name,
                code: obj.alpha_2_code,
              });
            });

            $scope.nationalities = nationalities;
            $scope.birth_countries = birth_countries;
            $scope.preloader = false;
          })
          .catch((e) => {})
          .finally(() => ($scope.preloader = false));
      }

      $scope.preloader = true;

      _system
        .countries()
        .then((a) => {
          const country = a.result.filter((item) => item.code === a.default)[0] || {};

          if (!$scope.formData.phone && angular.isString(country.prefix)) {
            $scope.formData.phone = `+${parseInt(country.prefix.replace(/\-/g, ''), 10)}`;
          }
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });

      $scope.submit = _helper.form.validator((form) => {
        $scope.preloader = true;

        if ($scope.formData.enable_trigger_codes !== true || $scope.hide_bonuses) {
          delete $scope.formData.trigger_codes;
        }
        delete $scope.formData.enable_trigger_codes;

        _paynplay
          .auth($scope.formData)
          .then((a) => {
            _p161.track('registration');
            if (_modal.current === modalName) _modal.answer = true;
          })
          .catch(() => {
            if ($scope.formData.trigger_codes) {
              $scope.formData.enable_trigger_codes = true;
            }
          })
          .finally(() => {
            $scope.preloader = false;
          });
      });
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
